<template>
    <div class="detWin" v-if="detWin == 1">
        <div class="box box_column around">
            <div class="box around mB20">
                <img src="../assets/images/healthy/jinggao-grey-icon.png" alt="" style="width: 60px; height: 54px" />
            </div>
            <!-- <div class="fs18" style="color: #333">请确认是否删除该构件/指标</div> -->
            <div class="fs18 msg">{{ msg }}</div>
            <div class="box around mT50">
                <div class="box">
                    <div class="btn1 mR40 pointer" @click="detWinClose">取消</div>
                    <div class="btn2 pointer" @click="detLine">确认</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        detWin: {
            type: Number,
            default: 0
        },
        msg: {
            type: String,
            default: "确认要删除吗？"
        },
        // img: {
        //     type: String,
        //     default: "../assets/images/healthy/jinggao-grey-icon.png"
        // }
    },
    data() {
        return {}
    },
    methods: {
        detWinClose() {
            this.$emit("close")
        },
        detLine() {
            this.$emit('confirm');
        }
    }
}
</script>


<style lang="scss" scoped>
.detWin {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 30%;
    z-index: 2;
    width: 500px;
    margin-left: -250px;
    height: 300px;
    //   background: #ffffff;
    //   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5), 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
    //   border-radius: 12px;
    padding-top: 66px;
    background: linear-gradient(180deg, #103D8F 0%, #092058 100%);
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    border: 1px solid;
    border-image: linear-gradient(119deg, rgba(17, 102, 255, 1), rgba(0, 50, 175, 0.95)) 1 1;


}

.btn1 {
    text-align: center;
    line-height: 32px;
    width: 96px;
    height: 32px;
    border: 1px solid #2667DB;
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
}

.btn2 {
    text-align: center;
    line-height: 32px;
    width: 96px;
    height: 32px;
    background: #2667DB;
    box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}

.bg1 {
    background-color: #061532;

    span {
        color: #5a5a5a;
    }
}

.msg {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 25px;
    letter-spacing: 1px;
    text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
}
</style>