<template>
  <div class="roleMain">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="hengBox"></div> -->

    <!-- <el-button @click="jumpAdd('add')">新建角色</el-button> -->
    <el-button @click="handleAddClick" :class="!myUpload ? 'disable_icon' : ''">新建角色</el-button>
    <el-table :row-class-name="tableRowClassName" class="mT30" :data="tableData" style="width: 100%" v-loading="loading" tooltip-effect="dark" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <el-table-column align="center" type="index" width="200" label="序号"></el-table-column>
      <el-table-column align="center" prop="name" label="角色名称"></el-table-column>
      <el-table-column align="center" prop="remark" label="备注"></el-table-column>
      <el-table-column align="center" prop="id" label="操作" width="300">
        <template slot-scope="scope">
          <span type="text" class="colorText mR20 pointer" size="small" @click="handleMenuClick(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">导航设置</span>
          <!-- <span type="text" class="colorText mR20 pointer" size="small" @click="jumpAdd(scope.row.id)">修改</span> -->
          <span type="text" class="colorText mR20 pointer" size="small" @click="handleEditClick(scope.row.id)" :class="!myOption ? 'disable_icon' : ''">修改权限</span>
          <span type="text" class="colorText pointer" size="small" @click="deleteRole(scope.row.id)" :class="!myDel ? 'disable_icon' : ''">删除</span>
        </template>
      </el-table-column>
      <template slot="empty">
        <span class="iconfont iconzanwushuju-"></span>
        <span>暂无数据</span>
      </template>
    </el-table>
    <common-pagination :total="total" class="pageMain" :currentPage="currentPage" @handleCurrentChange="handleCurrentChange" />
    <confirm :detWin="errWin" msg="确认删除?" @confirm="errbtn" @close="errClose"></confirm>
    <!-- 新建角色 -->
    <el-dialog title="角色权限设置" :visible.sync="addDialogVisible" class="common-dialog little-dialog" top="20vh" :before-close="handleAddDialogClose">
      <div class="operate-wrapper">
        <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="80px" class="my-form">
          <el-form-item label="角色信息" prop="roleName" style="width: 100%">
            <el-input v-model.trim="addForm.roleName" placeholder="请输入角色信息"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input type="textarea" v-model="addForm.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn-footer">
          <el-button class="save-btn" @click="handleAddSave">保存</el-button>
          <el-button class="cancel-btn" @click="handleAddDialogClose">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 导航栏设置 -->
    <el-dialog title="导航栏设置" :visible.sync="menuDialogVisible" class="common-dialog big-dialog" top="10vh" :before-close="handleMenuDialogClose">
      <el-divider class="my-divider"></el-divider>
      <div class="add-wrapper">
        <div class="list-wrapper">
          <el-checkbox v-model="defaultNameStatus" class="default-name" @change="handleDefaultNameChangeClick">默认名称</el-checkbox>
          <el-button class="template-btn" @click="handleTemplateClick">应用模板</el-button>
          <ul class="template-ul" v-show="isShowTemplateList">
            <li v-for="item in roleList" :key="item.id" @click="handleRoleTemplateClick(item)">{{ item.name }}</li>
          </ul>
        </div>
      </div>
      <el-divider class="my-divider"></el-divider>
      <div class="btns-wrapper">
        <div class="icon-wrapper" :class="activeItem == 'addParent' ? 'active-icon' : ''" @click="handleAddTop">
          <i class="icon iconfont icon-tianjia"></i>
          <span>新增节点</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'addChild' ? 'active-icon' : ''" @click="handleAddChildNode">
          <i class="icon iconfont icon-zilinwu"></i>
          <span>新增子节点</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'upgrade' ? 'active-icon' : ''" @click="handleUpgrade">
          <i class="icon iconfont icon-shengji"></i>
          <span>升级</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'downgrade' ? 'active-icon' : ''" @click="handleDownGrade">
          <i class="icon iconfont icon-shengjibeifen2"></i>
          <span>降级</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'up' ? 'active-icon' : ''" @click="handleNodeUp">
          <i class="icon iconfont icon-shangyi"></i>
          <span>上移</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'down' ? 'active-icon' : ''" @click="handleNodeDown">
          <i class="icon iconfont icon-xiayi"></i>
          <span>下移</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'edit' ? 'active-icon' : ''" @click="handleEditTreeNode">
          <i class="icon iconfont icon-bianji"></i>
          <span>编辑</span>
        </div>
        <div class="icon-wrapper" :class="activeItem == 'delete' ? 'active-icon' : ''" @click="handleDeleteTreeNode">
          <i class="icon iconfont icon-shanchu"></i>
          <span>删除</span>
        </div>
      </div>
      <div class="tree-wrapper">
        <div class="dialog-left-tree">
          <el-tree
            class="my-left-tree"
            :data="leftData"
            :default-expand-all="false"
            node-key="id"
            ref="leftTree"
            :expand-on-click-node="false"
            :default-checked-keys="defaultLeftKeys"
            :default-expanded-keys="defaultLeftExpandKeys"
            highlight-current
            @node-click="handleLeftNodeClick"
            :props="defaultLeftProps"
          >
            <span style="width: 100%; line-height: 34px" slot-scope="{ node, data }" @dblclick="handleDblClick(node, data, $event)">
              <span v-if="currDblClickId != data.id">
                {{ node.label }}
              </span>
              <span v-else class="node-wrapper">
                <el-input class="my-edit-input" :value="dbClickInputValue" maxlength="20" onkeyup="this.value=this.value.replace(/[, ]/g,'')" @keyup.enter.native="handleLeftEditSubmit(node, data)" @input="(a) => handleInputEvent(a, node, data)"> </el-input>
              </span>
            </span>
          </el-tree>
        </div>
        <div class="dialog-btn">
          <div class="dialog-btn-group">
            <el-button @click="handleBtnFromLeft">从左边移动到组<img src="@/assets/images/stress/jiantou2.png" alt="" style="margin-left: 5px" /></el-button>
            <el-button @click="handleBtnFromRight"><img src="@/assets/images/stress/jiantou1.png" alt="" style="margin-right: 5px" />从右边移动到组</el-button>
          </div>
        </div>
        <div class="dialog-right-tree">
          <ul class="modular-ul">
            <li v-for="item in modularList" :key="item.id" @click="handleModularClick(item)">
              {{ item.name }}
              <div class="bottom-line" v-if="activeModular == item.id"></div>
            </li>
          </ul>
          <el-divider class="my-divider"></el-divider>
          <el-checkbox-group v-model="commonActiveList" class="common-list" @change="handleCommonClick" v-show="activeModular == 1">
            <!-- 通用模块 -->
            <el-checkbox v-for="item in commonList" :key="item.id" :label="item.name" :disabled="item.status == 1" :class="commonActiveList.indexOf(item.name) >= 0 ? 'my-common-checkbox menu-active' : 'my-common-checkbox'"> </el-checkbox>
          </el-checkbox-group>
          <!-- 运维期模块 -->
          <el-checkbox-group v-model="operationActiveList" class="common-list" v-show="activeModular == 2">
            <el-checkbox v-for="item in operationList" :key="item.id" :label="item.name" :disabled="item.status == 1" :class="operationActiveList.indexOf(item.name) >= 0 ? 'my-common-checkbox menu-active' : 'my-common-checkbox'"> </el-checkbox>
          </el-checkbox-group>
          <!-- 施工期模块 -->
          <el-checkbox-group v-model="constructionActiveList" class="common-list" v-show="activeModular == 3">
            <el-checkbox v-for="item in constructionList" :key="item.id" :label="item.name" :disabled="item.status == 1" :class="constructionActiveList.indexOf(item.name) >= 0 ? 'my-common-checkbox menu-active' : 'my-common-checkbox'"> </el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="description">说明：编辑名称时按Enter保存</div>
      <el-divider class="my-divider"></el-divider>
      <div class="btn-footer">
        <el-button class="save-btn" @click="handleMenuSave">保存</el-button>
        <el-button class="cancel-btn" @click="handleMenuDialogClose">取消</el-button>
      </div>
    </el-dialog>
    <!-- 角色权限设置 -->
    <el-dialog title="角色权限设置" :visible.sync="roleDialogVisible" class="common-dialog big-dialog" top="10vh" :before-close="handleRoleDialogClose">
      <el-divider class="my-divider"></el-divider>
      <div class="add-wrapper">
        <el-form :model="roleForm" :rules="roleFormRules" ref="roleForm" :inline="true" class="my-role-form" label-width="90px">
          <el-form-item label="角色信息" prop="name">
            <el-input v-model="roleForm.name" placeholder="请输入角色信息"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="roleForm.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="roleDefaultNameStatus">默认名称</el-checkbox>
          </el-form-item>
        </el-form>
      </div>
      <el-divider class="my-divider"></el-divider>
      <div class="table-wrapper">
        <el-table :data="roleTableData" :span-method="handleSpanMethod">
          <el-table-column :prop="roleDefaultNameStatus ? 'oneOldName' : 'oneNewName'" label="一级导航"></el-table-column>
          <el-table-column :prop="roleDefaultNameStatus ? 'twoOldName' : 'twoNewName'" label="二级导航"></el-table-column>
          <el-table-column :prop="roleDefaultNameStatus ? 'threeOldName' : 'threeNewName'" label="三级导航"></el-table-column>
          <el-table-column prop="list" label="详情配置" width="280">
            <template slot-scope="scope">
              <ul class="info-ul">
                <li v-for="item in scope.row.list" :key="item.id">
                  <el-checkbox v-model="item.status">{{ item.name }}</el-checkbox>
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="操作" width="200">
            <template slot-scope="scope">
              <el-radio-group v-model="scope.row.leadings">
                <el-radio :label="1">主导航</el-radio>
                <el-radio :label="2">副导航</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-divider class="my-divider"></el-divider>
      <div class="btn-footer">
        <el-button class="save-btn" @click="handleRoleSave">保存</el-button>
        <el-button class="cancel-btn" @click="handleRoleDialogClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CommonPagination from "../../../components/Pagination";
import confirm from "../../../components/confirm";
export default {
  name: "roleList",
  components: {
    CommonPagination,
    confirm
  },
  data() {
    return {
      errWin: 0,
      total: 0,
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      loading: false,
      id: null,
      activeItem: "",
      defaultNameStatus: false, // 默认名称
      addDialogVisible: false,
      menuDialogVisible: false,
      roleDialogVisible: false,
      addForm: {},
      roleList: [
        // {
        //   id: 1,
        //   name: '超级管理员'
        // },
        // {
        //   id: 2,
        //   name: '管理员'
        // },
        // {
        //   id: 3,
        //   name: '普通用户'
        // }
      ],
      activeModular: "1",
      modularList: [
        {
          id: 1,
          name: "通用模块"
        },
        {
          id: 2,
          name: "运维期模块"
        },
        {
          id: 3,
          name: "施工期模块"
        }
      ],
      // 通用模块
      commonList: [],
      // 通用模块 选中
      commonActiveList: [],
      // 运维期模块 选中
      operationActiveList: [],
      // 运维期模块
      operationList: [],
      // 施工期模块
      constructionList: [],
      // 施工期模块 选中
      constructionActiveList: [],
      addFormRules: {
        roleName: [{ required: true, message: "请输入角色信息", trigger: "blur" }]
      },
      // 左树
      leftData: [],
      defaultLeftKeys: [],
      defaultLeftExpandKeys: [],
      defaultLeftProps: {
        children: "children",
        label: "name"
      },
      // 双击的节点id
      currDblClickId: "",
      // 双击的节点内容
      dbClickInputValue: "",
      // rightData: [],
      // defaultRightKeys: [],
      // defaultRightProps: {
      //   children: "children",
      //   label: "targetName"
      // },
      // 是否显示应用模板下拉列表
      isShowTemplateList: false,
      // 左树 当前选中的节点
      leftCurrentSelectNode: {},
      // 自增id
      majorId: new Date().getTime(),
      // 是否存在空分组
      emptyNum: 0,
      // 当前操作的角色id
      currentId: "",
      // 导航栏设置 发送给后端的数据 转换格式
      sendData: [],
      // 角色权限设置
      roleForm: {},
      // 校验规则
      roleFormRules: {
        name: [{ required: true, message: "请输入角色信息", trigger: "blur" }]
      },
      // 角色权限设置 表格数据
      roleTableData: [],
      // 角色权限设置 默认名称
      roleDefaultNameStatus: false,
      spanArr: [],
      mypos: 0,
      spanArrSecond: [],
      myposSecond: 0
    };
  },
  mounted() {
    this.getDataList();
    let majorId = sessionStorage.getItem("majorId");
    if (!majorId) {
      sessionStorage.setItem("majorId", new Date().getTime());
    } else {
      sessionStorage.setItem("majorId", majorId);
    }
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      //条纹变色
      if (rowIndex % 2 == 1) {
        return "hui1";
      } else {
        return "lv1";
      }
    },

    jumpAdd(id) {
      this.$router.push("/addRole/" + id);
    },
    // 获取表格数据
    getDataList() {
      this.$axios.get(`${this.baseURL}auth/authRole/all/${this.currentPage}/${this.pageSize}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.total = res.data.total;
          this.tableData = res.data.records;
        }
      });
    },
    // 获取右侧导航模块数据 通用模块1 运维模块 2 施工模块3
    getNavList(type, roleId, callback) {
      this.$axios.get(`${this.baseURL}navigation/type?type=${type}&roleId=${roleId}`).then((res) => {
        console.log("获取右侧导航模块的数据---", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          detail.forEach((item) => {
            item.oldName = item.name;
            item.newName = item.name;
            item.flag = item.type;
            item.uid = item.id;
            item.myType = 1; // 分组为0 导航为1
          });
          callback(detail);
          // type == 1 ? this.commonList = detail : type == 2 ? this.operationList = detail : this.constructionList = detail
        }
      });
    },
    // 左树数据格式化
    formatGetLeftData(list) {
      list.forEach((item) => {
        item.myType = item.type;
        if (item.children && item.children.length) {
          this.formatGetLeftData(item.children);
        }
      });
    },
    // 获取左侧自定义结构树
    getLeftTreeData(id) {
      this.$axios.get(`${this.baseURL}navigation/select/group/${id}`).then((res) => {
        console.log("获取左侧树结构的数据---", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          detail.defaults == 0 ? (this.defaultLeftProps.label = "newName") : (this.defaultLeftProps.label = "oldName");
          // this.formatGetLeftData(detail.list, result => {
          //   console.log('获取左树重构后的数据---000---', result)
          //   this.leftData = result
          // })
          this.formatGetLeftData(detail.list);
          this.leftData = detail.list;
          if (detail.list && detail.list.length) {
            this.defaultLeftExpandKeys = [detail.list[0].id];
          }
        }
      });
    },
    // 获取应用模板下拉数据
    getRoleList() {
      this.$axios.get(`${this.baseURL}navigation/company/role/`).then((res) => {
        console.log("获取应用模板下拉数据---", res);
        if (res.data.status == "200") {
          this.roleList = res.data.data;
        }
      });
    },
    // 新建角色
    handleAddClick() {
      if (!this.myUpload) {
        this.$message.error("您没有权限！");
        return;
      }
      this.addDialogVisible = true;
    },
    // 新建角色 保存
    handleAddSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let data = {
            name: this.addForm.roleName, // 角色名称
            remark: this.addForm.remark, // 备注
            permissionId: ""
          };
          this.$axios.post(`${this.baseURL}auth/authRole/`, this.$qs.stringify(data)).then((res) => {
            console.log("添加角色", res);
            if (res.data.status == "200") {
              this.$message.success("保存成功");
              this.addDialogVisible = false;
              this.$refs.addForm.resetFields();
              this.getDataList();
            }
          });
        }
      });
    },
    // 导航设置
    handleMenuClick(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.activeModular = 1;
      this.currentId = id;
      this.leftCurrentSelectNode = {};
      this.menuDialogVisible = true;
      this.getNavList(1, id, (result) => {
        this.commonList = result;
      });

      this.getNavList(2, id, (result) => {
        this.operationList = result;
      });

      this.getNavList(3, id, (result) => {
        this.constructionList = result;
      });
      this.getLeftTreeData(id);
      this.getRoleList();
    },
    // 获取权限详情
    getRoleInfo(roleId) {
      this.spanArr = [];
      this.mypos = 0;
      this.spanArrSecond = [];
      this.myposSecond = 0;
      this.$axios.get(`${this.baseURL}navigation/power/${roleId}`).then((res) => {
        console.log("获取角色权限详情信息---", res);
        if (res.data.status == "200") {
          let detail = res.data.data;
          this.roleForm = detail;
          detail.list.forEach((item) => {
            if (item.list && item.list.length) {
              item.list.forEach((eve) => {
                eve.status == 0 ? (eve.status = false) : (eve.status = true);
              });
            }
          });
          this.roleDefaultNameStatus = detail.defaults == 1;
          this.roleTableData = detail.list;

          // 获取单元格合并树
          this.getFirstMerge(detail.list);
          this.getSecondMerge(detail.list);

          console.log("当前单元格合并情况----------", this.spanArr, this.spanArrSecond);
        }
      });
    },
    // 修改权限
    handleEditClick(id) {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      this.currentId = id;
      this.roleDialogVisible = true;
      this.getRoleInfo(id);
    },
    // 关闭新建角色弹窗
    handleAddDialogClose() {
      this.addDialogVisible = false;
      this.addForm = {};
      this.$refs.addForm.resetFields();
    },
    // 导航栏设置弹窗
    handleMenuDialogClose() {
      this.menuDialogVisible = false;
      this.leftData = [];
    },
    // 默认名称 开启/关闭
    handleDefaultNameChangeClick(val) {
      console.log("点击默认名称是否处于开启状态", val);
      if (val) {
        // 开启默认名称 显示导航默认名称
        this.defaultLeftProps.label = "oldName";
      } else {
        // 关闭默认名称 显示导航自定义名称
        this.defaultLeftProps.label = "newName";
      }
    },
    // 应用模板 点击
    handleTemplateClick() {
      this.isShowTemplateList = !this.isShowTemplateList;
    },
    // 应用模板 选择角色 提示是否应用
    handleRoleTemplateClick(item) {
      this.$confirm(`请确认是否应用${item.name}？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.getLeftTreeData(item.id);
        this.isShowTemplateList = false;
        this.getNavList(1, item.id, (result) => {
          this.commonList = result;
        });

        this.getNavList(2, item.id, (result) => {
          this.operationList = result;
        });

        this.getNavList(3, item.id, (result) => {
          this.constructionList = result;
        });
      });
    },
    // 点击不同的模块
    handleModularClick(item) {
      this.activeModular = item.id;
      // this.getNavList(item.id)
    },
    // 通用模块 点击/选中
    handleCommonClick(val) {
      console.log("点击-------", val);
      // if (this.commonActiveList.indexOf(item.name) < 0) {
      //   this.commonActiveList.push(item.name)
      // }
    },
    // 新增节点
    handleAddTop() {
      let node = {
        id: this.majorId++,
        name: "分组",
        oldName: "分组",
        newName: "分组",
        pid: 0,
        level: 1,
        type: 0,
        myType: 0 // 0 分组 1 导航
      };
      this.leftData.push(node);
      sessionStorage.setItem("majorId", this.majorId++);
    },
    // 新增子节点
    handleAddChildNode() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      if (node.level == 3) {
        this.$message.warning("超出分组层级限制");
        return;
      }
      if (node.myType == 1) {
        this.$message.warning("页面节点下不可增加子节点");
        return;
      }

      let childNode = {
        id: this.majorId++,
        pid: node.id,
        name: "分组",
        oldName: "分组",
        newName: "分组",
        level: node.level + 1,
        type: 0,
        myType: 0
      };
      this.$refs.leftTree.append(childNode, this.leftCurrentSelectNode);
      this.defaultLeftExpandKeys = [node.id];
      sessionStorage.setItem("majorId", this.majorId++);
      console.log("当前左侧结构树数据", this.leftData);
    },
    // 升级
    handleUpgrade() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      if (node.level == 1) {
        this.$message.warning("该节点已是最高级别");
        return;
      }
      let pNode = this.$refs.leftTree.getNode(node.pid);
      console.log("升级操作---获取父节点---", pNode);
      if (!pNode) {
        this.$message.warning("该节点已是最高级别");
        return;
      }

      node.pid = pNode.data.pid;
      node.level = pNode.data.level;
      this.$refs.leftTree.remove(node);
      this.$refs.leftTree.insertAfter(node, pNode);
      this.leftCurrentSelectNode = {};
    },
    // 降级
    handleDownGrade() {
      let node = this.leftCurrentSelectNode;
      console.log("选中的树节点---要降级---", node);
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      if (node.level == 3) {
        this.$message.warning("未查询到同层级节点");
        return;
      }
      if (node.pid == 0 || node.level == 1) {
        console.log("leftdata---", this.leftData);
        this.leftData.forEach((item, index) => {
          if (item.id == node.id) {
            console.log("index----", index);
            if (index == 0) {
              // 向下降级
              let nextNode = this.leftData[index + 1];
              if (nextNode.myType == 1) {
                this.$message.warning("页面节点下不可增加子节点");
                return;
              }
              console.log("找到了向下降级的节点----", nextNode);
              node.level = nextNode.level + 1;
              node.pid = nextNode.id;
              this.$refs.leftTree.remove(node);
              this.$refs.leftTree.append(node, nextNode);
              let lightNode = this.$refs.leftTree.getNode(node.id);
              this.$set(lightNode, "isCurrent", true);
              this.defaultLeftExpandKeys = [node.id];
            } else {
              // 向上降级
              let prevNode = this.leftData[index - 1];
              if (prevNode.myType == 1) {
                this.$message.warning("页面节点下不可增加子节点");
                return;
              }
              console.log("找到了向上降级的节点----", prevNode);
              node.pid = prevNode.id;
              node.level = prevNode.level + 1;
              this.$refs.leftTree.remove(node);
              this.$refs.leftTree.append(node, prevNode);
              let lightNode = this.$refs.leftTree.getNode(node.id);
              this.$set(lightNode, "isCurrent", true);
              this.defaultLeftExpandKeys = [node.id];
            }
          }
        });
      } else {
        let pNode = this.$refs.leftTree.getNode(node.pid);
        console.log("降级操作--获取父节点", pNode);
        if (pNode.data.children && pNode.data.children.length) {
          let childNodes = pNode.data.children;
          if (childNodes.length == 1) {
            this.$message.warning("未查询到同层级节点");
            return;
          }
          childNodes.forEach((item, index) => {
            if (item.id == node.id) {
              if (index == 0) {
                // 向下降级
                let nextNode = childNodes[index + 1];
                if (nextNode.myType == 1) {
                  this.$message.warning("页面节点下不可增加子节点");
                  return;
                }
                console.log("找到了向下降级的节点----", nextNode);
                node.level = nextNode.level + 1;
                node.pid = nextNode.id;
                this.$refs.leftTree.remove(node);
                this.$refs.leftTree.append(node, nextNode);
                let lightNode = this.$refs.leftTree.getNode(node.id);
                this.$set(lightNode, "isCurrent", true);
                this.defaultLeftExpandKeys = [node.id];
              } else {
                // 向上降级
                let prevNode = childNodes[index - 1];
                if (prevNode.myType == 1) {
                  this.$message.warning("页面节点下不可增加子节点");
                  return;
                }
                console.log("找到了向上降级的节点----", prevNode);
                node.pid = prevNode.id;
                node.level = prevNode.level + 1;
                this.$refs.leftTree.remove(node);
                this.$refs.leftTree.append(node, prevNode);
                let lightNode = this.$refs.leftTree.getNode(node.id);
                this.$set(lightNode, "isCurrent", true);
                this.defaultLeftExpandKeys = [node.id];
              }
            }
          });
        }
      }
    },
    // 上移
    handleNodeUp() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      let pNode = this.$refs.leftTree.getNode(node.pid);
      console.log("获取当前上移节点的父节点", pNode);
      if (!pNode) {
        // 一级父节点
        this.leftData.forEach((item, index) => {
          if (item.id == node.id) {
            if (index == 0) {
              this.$message.warning("该节点已在当前层级最首位");
              return;
            }
            let prevNode = this.leftData[index - 1];
            this.$refs.leftTree.remove(node);
            this.$refs.leftTree.insertBefore(node, prevNode);
            let lightNode = this.$refs.leftTree.getNode(node.id);
            this.$set(lightNode, "isCurrent", true);
          }
        });
      } else {
        let childnodes = pNode.data.children;
        childnodes.forEach((item, index) => {
          if (item.id == node.id) {
            if (index == 0) {
              this.$message.warning("该节点已在当前层级最首位");
            } else {
              let prevNode = childnodes[index - 1];
              this.$refs.leftTree.remove(node);
              this.$refs.leftTree.insertBefore(node, prevNode);
              let lightNode = this.$refs.leftTree.getNode(node.id);
              this.$set(lightNode, "isCurrent", true);
            }
          }
        });
      }
    },
    // 下移
    handleNodeDown() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      let pNode = this.$refs.leftTree.getNode(node.pid);
      console.log("下移------获取父节点", pNode);
      if (!pNode) {
        // 一级父节点
        let getIndex;
        this.leftData.forEach((item, index) => {
          if (item.id == node.id) {
            getIndex = index;
          }
        });
        if (getIndex == this.leftData.length - 1) {
          this.$message.warning("该节点已在当前层级最末位");
        } else {
          let nextNode = this.leftData[getIndex + 1];
          this.$refs.leftTree.remove(node);
          this.$refs.leftTree.insertAfter(node, nextNode);
          let lightNode = this.$refs.leftTree.getNode(node.id);
          this.$set(lightNode, "isCurrent", true);
        }
      } else {
        let childNodes = pNode.data.children;
        let getIndex;
        childNodes.forEach((item, index) => {
          if (item.id == node.id) {
            getIndex = index;
          }
        });
        if (getIndex == childNodes.length - 1) {
          this.$message.warning("该节点已在当前层级最末位");
        } else {
          let nextNode = childNodes[getIndex + 1];
          this.$refs.leftTree.remove(node);
          this.$refs.leftTree.insertAfter(node, nextNode);
          let lightNode = this.$refs.leftTree.getNode(node.id);
          this.$set(lightNode, "isCurrent", true);
        }
      }
    },
    // 编辑
    handleEditTreeNode() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      if (this.defaultNameStatus && node.myType == 1) {
        this.$message.warning("默认名称开启，禁止重命名");
        return;
      }
      this.currDblClickId = this.leftCurrentSelectNode.id;
      this.dbClickInputValue = this.leftCurrentSelectNode.newName;
    },
    // 递归 删除 从左到右回归节点
    removeNodes(list) {
      list.forEach((item) => {
        console.log("---", item);
        if (item.myType == 1) {
          // 右树启用相应节点
          if (item.flag == 1 || item.typeRel == 1) {
            console.log("这是通用模块里的---删除---");
            this.commonList.forEach((eve) => {
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          if (item.flag == 2 || item.typeRel == 2) {
            console.log("这是运维模块里的---删除---");
            this.operationList.forEach((eve) => {
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          if (item.flag == 3 || item.typeRel == 3) {
            console.log("这是施工模块里的---删除---");
            this.constructionList.forEach((eve) => {
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          setTimeout(() => {
            this.$refs.leftTree.remove(item);
          }, 500);
          if (item.children && item.children.length) {
            this.resetNodes(item.children);
          }
        } else {
          if (item.children && item.children.length) {
            this.removeNodes(item.children);
          }
        }
      });
    },
    // 删除
    handleDeleteTreeNode() {
      let node = this.leftCurrentSelectNode;
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      this.$confirm(`确认删除？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        console.log("111111111111111111111111", node);
        if (node.myType == 0) {
          // 自定义节点 判断子级是否存在关联过来的节点
          if (node.children && node.children.length) {
            this.removeNodes(node.children);
            setTimeout(() => {
              this.$refs.leftTree.remove(node);
            }, 100);
          } else {
            this.$refs.leftTree.remove(node);
          }
        } else {
          // 关联过来的节点
          this.removeNodes([node]);
          this.leftCurrentSelectNode = {};
        }
        this.leftCurrentSelectNode = {};

        // 需要判断节点以及里面的子节点是否包含导航页面，如有，则归位
        // if (node.children && node.children.length) {
        //   node.children.forEach(item => {
        //     if (item.myType == 1) {
        //       // 需要归位
        //       console.log('子节点需要归位')
        //       this.removeNodes(node.children)
        //     }
        //   })
        // } else {
        //   if (node.myType == 1) {
        //     // 需要归位
        //     console.log('父节点需要归位')
        //     this.removeNodes([node])
        //   }
        // }
        // setTimeout(() => {
        //   this.$refs.leftTree.remove(node)
        // }, 500);
        // this.leftCurrentSelectNode = {}
      });
    },
    // 左树 点击
    handleLeftNodeClick(data, node) {
      console.log(data);
      if (this.currDblClickId == data.id) {
        return;
      }
      if (data.id == this.leftCurrentSelectNode.id) {
        console.log("点击同一个");
        let currentNode = this.$refs.leftTree.getNode(data.id);
        this.$set(currentNode, "isCurrent", false);
        this.leftCurrentSelectNode = {};
      } else {
        this.currDblClickId = "";
        this.leftCurrentSelectNode = data;
      }

      let ele = document.getElementsByClassName("is-current");
      console.log("选中状态的节点------------------------------------", ele);
      if (ele && ele.length) {
        ele[0].setAttribute("class", "el-tree-node is-focusable");
      }
    },
    // 双击节点
    handleDblClick(node, data, event) {
      console.log(data);
      console.log("当前是否开启默认名称", this.defaultNameStatus);
      if (this.defaultNameStatus && data.myType == 1) {
        // 开启默认名称状态 禁止对导航名称重命名
        this.$message.warning("默认名称开启，禁止重命名");
        return;
      }
      this.currDblClickId = data.id;
      this.dbClickInputValue = data.newName;
      let currentNode = this.$refs.leftTree.getNode(data.id);
      this.$set(currentNode, "isCurrent", true);
    },
    // 回车
    handleLeftEditSubmit(node, data) {
      console.log("回车事件--当前的value值", this.dbClickInputValue);
      console.log("node", node, "data", data);
      // 默认名称状态开启，禁止重命名
      if (this.defaultNameStatus) {
        this.$message.warning("默认名称开启，禁止重命名");
        this.currDblClickId = "";
        return;
      }
      data.newName = this.dbClickInputValue;
      if (data.type == 0) {
        data.oldName = this.dbClickInputValue;
        data.name = this.dbClickInputValue;
      }
      this.currDblClickId = "";
      this.leftCurrentSelectNode = data;
    },
    // input
    handleInputEvent(value, node, data) {
      this.dbClickInputValue = value;
    },
    // 递归 从左移动到右
    resetNodes(list) {
      list.forEach((item) => {
        console.log("---", item, "leftData", this.leftData);
        if (item.myType == 1) {
          // 右树启用相应节点
          if (item.flag == 1 || item.typeRel == 1) {
            console.log("从左到右--这是通用模块里的---");
            this.commonList.forEach((eve) => {
              console.log("commonList----", eve);
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          if (item.flag == 2 || item.typeRel == 2) {
            console.log("这是运维模块里的---");
            this.operationList.forEach((eve) => {
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          if (item.flag == 3 || item.typeRel == 3) {
            console.log("这是施工模块里的---");
            this.constructionList.forEach((eve) => {
              if (eve.id == item.uid) {
                // item.disabled = false
                eve.status = 0;
              }
            });
          }
          // 左树移除相应节点
          // this.$refs.leftTree.remove(item)
          setTimeout(() => {
            this.$refs.leftTree.remove(item);
          }, 200);
          if (item.children && item.children.length) {
            this.resetNodes(item.children);
          }
        } else {
          if (item.children && item.children.length) {
            this.resetNodes(item.children);
          }
        }
      });
    },
    // 从左到右
    handleBtnFromLeft() {
      let node = this.leftCurrentSelectNode;
      console.log("从左到右-------------", node);
      if (!node.id) {
        this.$message.warning("请选择树节点");
        return;
      }
      if (node.myType == 0) {
        // 自定义的节点
        if (node.children && node.children.length) {
          // 子节点是否有右侧关联过来的节点
          this.resetNodes(node.children);
        }
      } else {
        // 关联过来的节点
        this.resetNodes([node]);
        this.leftCurrentSelectNode = {};
      }
      // this.leftCurrentSelectNode = {}
    },
    // 从右到左 通用函数
    // fromRightToLeft(list, activeList, flag) {
    //   if (!activeList.length) {
    //     this.$message.warning('请选择导航节点')
    //     return
    //   }
    //   let nodesArr = []
    //   // 获取右侧选中的导航节点
    //   list.forEach(item => {
    //     activeList.forEach(eve => {
    //       if (eve == item.name) {
    //         item.flag = flag // 从什么模块而来
    //         nodesArr.push(item)
    //       }
    //     })
    //   })
    //   console.log('获取到选中的导航节点----', nodesArr)

    //   nodesArr.forEach(item => {
    //     if (!this.leftCurrentSelectNode.id) {
    //       // 左树未选中节点，直接当父节点
    //       this.leftData.unshift(...nodesArr)
    //     } else {
    //       // 挂载到左树相应的节点，成为子节点
    //       this.$refs.leftTree.append(item, this.leftCurrentSelectNode)
    //     }
    //     // 右侧相应节点禁用置灰
    //     list.forEach(eve => {
    //       if (eve.id == item.id) {
    //         item.disabled = true
    //       }
    //     })
    //     activeList = []
    //   })
    // },
    // 从右到左
    handleBtnFromRight() {
      let currentNode = this.leftCurrentSelectNode;
      if (currentNode.myType == 1) {
        this.$message.warning("页面节点下不可增加子节点");
        return;
      }
      if (currentNode.level == 3) {
        this.$message.warning("超出分组层级限制");
        return;
      }
      if (this.activeModular == 1) {
        // 通用模块
        console.log("通用模块---从右到左---", this.commonActiveList, "当前选中的节点---", currentNode);
        if (!this.commonActiveList.length) {
          this.$message.warning("请选择导航节点");
          return;
        }
        let nodesArr = [];
        // 获取右侧选中的导航节点
        this.commonList.forEach((item) => {
          this.commonActiveList.forEach((eve) => {
            if (eve == item.name) {
              // item.flag = this.activeModular
              nodesArr.push(item);
            }
          });
        });
        console.log("获取到选中的导航节点----", nodesArr);

        if (!currentNode.id) {
          // 左树未选中节点，直接当父节点
          this.leftData.push(...nodesArr);
          nodesArr.forEach((item) => {
            this.commonList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        } else {
          nodesArr.forEach((item) => {
            item.pid = currentNode.id;
            // 挂载到左树相应的节点，成为子节点
            this.$refs.leftTree.append(item, currentNode);
            this.defaultLeftExpandKeys = [currentNode.id];
            // 右侧相应节点禁用置灰
            this.commonList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        }
        this.commonActiveList = [];
      }
      if (this.activeModular == 2) {
        // 运维模块
        console.log("运维模块---从右到左---", this.operationActiveList);
        if (!this.operationActiveList.length) {
          this.$message.warning("请选择导航节点");
          return;
        }
        let arr = [];
        // 获取右侧选中的导航节点
        this.operationList.forEach((item) => {
          this.operationActiveList.forEach((eve) => {
            if (eve == item.name) {
              item.flag = this.activeModular;
              arr.push(item);
            }
          });
        });

        if (!currentNode.id) {
          // 左树未选中节点，直接当父节点
          this.leftData.push(...arr);
          arr.forEach((item) => {
            this.operationList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        } else {
          arr.forEach((item) => {
            item.pid = currentNode.id;
            // 挂载到左树相应的节点，成为子节点
            this.$refs.leftTree.append(item, currentNode);
            this.defaultLeftExpandKeys = [currentNode.id];
            // 右侧相应节点禁用置灰
            this.operationList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        }
        this.operationActiveList = [];
      }
      if (this.activeModular == 3) {
        // 施工期模块
        console.log("施工模块---从右到左---", this.constructionActiveList);
        if (!this.constructionActiveList.length) {
          this.$message.warning("请选择导航节点");
          return;
        }
        let arrs = [];
        // 获取右侧选中的导航节点
        this.constructionList.forEach((item) => {
          this.constructionActiveList.forEach((eve) => {
            if (eve == item.name) {
              item.flag = this.activeModular;
              arrs.push(item);
            }
          });
        });

        if (!currentNode.id) {
          // 左树未选中节点，直接当父节点
          this.leftData.push(...arrs);
          arrs.forEach((item) => {
            this.constructionList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        } else {
          arrs.forEach((item) => {
            item.pid = currentNode.id;
            // 挂载到左树相应的节点，成为子节点
            this.$refs.leftTree.append(item, currentNode);
            this.defaultLeftExpandKeys = [currentNode.id];
            // 右侧相应节点禁用置灰
            this.constructionList.forEach((eve) => {
              if (eve.id == item.id) {
                // item.disabled = true
                item.status = 1;
              }
            });
          });
        }
        this.constructionActiveList = [];
      }
    },
    // 检查左树是否存在空分组
    checkIsHasEmpty(list) {
      list.forEach((item) => {
        if (item.myType == 0 && !item.children) {
          this.emptyNum++;
        } else {
          if (item.children && item.children.length) {
            this.checkIsHasEmpty(item.children);
          }
        }
      });
    },
    // 转换后台所需格式
    formatLeftData(list) {
      list.forEach((item) => {
        if (this.defaultNameStatus) {
          item.name = item.oldName;
        } else {
          item.name = item.newName;
        }
        if (item.children && item.children.length) {
          this.formatLeftData(item.children);
        }
      });
    },
    // 导航栏设置 保存
    handleMenuSave() {
      this.emptyNum = 0;
      this.sendData = [];
      // console.log('导航栏设置保存时，判断左侧树有没有空文件夹', this.leftData)
      if (!this.leftData.length) {
        this.$message.warning("未生成分组，保存失败");
        return;
      }
      this.checkIsHasEmpty(this.leftData);
      console.log("是否存在空分组---", this.emptyNum, this.leftData);
      if (this.emptyNum > 0) {
        this.$message.warning("存在空分组，保存失败");
        return;
      }
      this.formatLeftData(this.leftData);
      console.log("发送给后端的数据---", this.leftData);

      this.$axios.post(`${this.baseURL}navigation/save/group/${this.currentId}`, this.leftData).then((res) => {
        console.log("导航栏设置保存操作----", res);
        if (res.data.status == "200") {
          this.$message.success("保存成功");
          this.menuDialogVisible = false;
        }
      });
    },
    // 角色权限设置 保存
    handleRoleSave() {
      console.log("角色权限保存的时候---表格数据---", this.roleTableData);
      let data = {
        defaults: this.roleDefaultNameStatus ? 1 : 0, // 默认名称
        leadings: [], // 副导航id集合
        list: [], // 权限操作id集合
        name: this.roleForm.name, // 角色名称
        remark: this.roleForm.remark, // 备注
        roleId: this.currentId // 角色id
      };
      this.roleTableData.forEach((item, index) => {
        // 副导航id集合
        if (item.leadings == 2 && this.spanArr[index] > 0) {
          console.log("查看是否是被合并的状态------", this.spanArr[index]);
          data.leadings.push(item.oneId);
        }
        // 操作权限id集合
        if (item.list && item.list.length) {
          item.list.forEach((eve) => {
            if (eve.status) {
              data.list.push(eve.id);
            }
          });
        }
      });
      console.log("角色权限保存---发送给后端的数据---", data);
      this.$axios.post(`${this.baseURL}navigation/add/power`, data).then((res) => {
        console.log("角色权限设置保存操作----00000----", res);
        if (res.data.status == "200") {
          this.$message.success("保存成功");
          this.roleDialogVisible = false;
        }
      });
    },
    // 获取每行合并数 针对一级导航
    getFirstMerge(data) {
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          this.spanArr.push(1);
          this.mypos = 0;
        } else {
          if (data[i].oneId == data[i - 1].oneId) {
            this.spanArr[this.mypos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.mypos = i;
          }
        }
      }
    },
    // 获取二级导航合并数
    getSecondMerge(data) {
      for (let i = 0; i < data.length; i++) {
        if (i == 0) {
          this.spanArrSecond.push(1);
          this.myposSecond = 0;
        } else {
          if (data[i].twoId == data[i - 1].twoId) {
            this.spanArrSecond[this.myposSecond] += 1;
            this.spanArrSecond.push(0);
          } else {
            this.spanArrSecond.push(1);
            this.myposSecond = i;
          }
        }
      }
    },
    // 合并单元格
    handleSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 一级导航、操作列合并
      if (columnIndex == 0 || columnIndex == 4) {
        return {
          rowspan: this.spanArr[rowIndex],
          colspan: 1
        };
      }
      // 二级导航合并
      if (columnIndex == 1 && row.twoId) {
        return {
          rowspan: this.spanArrSecond[rowIndex],
          colspan: 1
        };
      }
    },
    // 关闭弹窗角色权限设置
    handleRoleDialogClose() {
      this.roleDialogVisible = false;
    },
    // 删除
    deleteRole(id) {
      if (!this.myDel) {
        this.$message.error("您没有权限！");
        return;
      }
      this.$confirm(`确认删除？`, "删除", {
        dangerouslyUseHTMLString: true,
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        closeOnClickModal: false,
        cancelButtonClass: "btn-cancel",
        confirmButtonClass: "btn-confirm"
      }).then(() => {
        this.$axios.delete(`${this.baseURL}auth/authRole/${id}`).then((res) => {
          // console.log('res===删除', res)
          // return;
          res = res.data;
          if (res.errCode == 200) {
            this.$message({
              message: "删除成功！",
              type: "success"
            });
            this.getDataList();
          } else {
            this.$message({
              message: res.errMsg,
              type: "error"
            });
          }
        });
      });
      // this.id = id
      // this.errWin = 1
    },
    errbtn() {
      this.errWin = 0;
      this.deleteItem(this.id);
    },
    errClose() {
      this.errWin = 0;
    },
    deleteItem(id) {
      this.$axios.delete(`${this.baseURL}auth/authRole/${id}`).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.$message({
            message: "删除成功！",
            type: "success"
          });
          this.getDataList();
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    }
  }
};
</script>

<style scoped lang="scss">
.pageMain {
  margin-top: 40px;
}

::v-deep .hui1 {
  background: #091d46 !important;
}

::v-deep .lv1 {
  background: #0c265a !important;
}
::v-deep .my-left-tree .el-tree-node__content {
  height: 30px;
  line-height: 30px;
}
::v-deep .el-tree.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background: rgba(50, 116, 255, 0.23) !important;
}
.template-btn {
  width: 96px;
  margin-left: 20px;
}
::v-deep .default-name span.el-checkbox__label {
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
}
::v-deep div.el-dialog .my-role-form .el-input {
  width: 200px;
  height: 34px;
  line-height: 34px;
}
/* ::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  transform: translate(-45%,-45%) scale(1.5);
} */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #193f7f;
}
::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background-color: #2772f0;
}
::v-deep .el-radio__inner {
  border: 1px solid #2772f0;
  background-color: #193f7f;
}
::v-deep .table-wrapper {
  height: calc(100% - 115px);
  overflow: auto;
  div.el-table th,
  div.el-table td {
    height: 32px;
    line-height: 32px;
    padding: 2px 0;
    div.cell {
      font-size: 13px !important;
      text-align: center;
    }
  }
  div.el-table th {
    background: #194487 !important;
  }
  div.el-table td {
    height: 36px;
    line-height: 36px;
  }
  div.el-table tr:nth-child(odd) td {
    background: #163c77 !important;
  }
  div.el-table tr:nth-child(even) td {
    background: #123367 !important;
  }
  td,
  th {
    /* background: #123367 !important; */
    border-right: 1px solid #275090 !important;
    border-bottom: 1px solid #275090 !important;
  }
  /* tr:last-child td {
    border-bottom: 1px solid #275090 !important;
  } */
  /* td:not(:last-child),
  th:not(:last-child) {
    border-right: 1px solid #275090 !important;
  } */
}
.info-ul {
  display: flex;
  li {
    margin-right: 15px;
    ::v-deep span.el-checkbox__label {
      font-size: 13px !important;
    }
  }
}
.description {
  margin-top: 20px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  color: #9fc0fe;
  letter-spacing: 1px;
  padding-bottom: 20px;
}
.template-ul {
  position: absolute;
  top: 40px;
  width: 150px;
  height: 220px;
  overflow: auto;
  background: #082e69;
  border: 1px solid #2361cd;
  border-radius: 4px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 14px;
  z-index: 88;
  color: #759ecf;
  text-align: left;
  li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 20px;
    &:hover {
      cursor: pointer;
      background: #2667db;
      color: #fff;
    }
  }
}
.modular-ul {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  li {
    position: relative;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: #b1c6ff;
    &:hover {
      cursor: pointer;
    }
    .bottom-line {
      position: absolute;
      width: 100%;
      height: 8px;
      top: 15px;
      background: url("../../../assets/images/report/juxing.png");
      background-size: 100% 100%;
    }
  }
  li.active {
    color: #fff;
  }
}
.menu-active {
  background: rgba(50, 116, 255, 0.23);
}
.common-list {
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: none;
  color: #fff;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  .my-common-checkbox {
    height: 30px;
    line-height: 30px;
    margin-top: 10px;
    padding-left: 20px;
    margin-right: 0;
    ::v-deep .el-checkbox__label {
      font-family: PingFangSC-Regular;
      font-weight: 400;
      font-size: 12px;
      margin-left: 20px;
    }
  }
}
.common-ul {
  height: calc(100% - 45px);
  overflow-y: auto;
  background: none;
  color: #fff;
  font-size: 12px;
  font-family: PingFangSC-Regular;
  line-height: 2;
  li {
    height: 30px;
    line-height: 30px;
    padding-left: 20px;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
    .el-checkbox {
      margin-right: 25px;
    }
  }
}
::v-deep span.el-checkbox__input.is-disabled .el-checkbox__inner {
  background: #edf2fc !important;
}
::v-deep .el-checkbox__label {
  width: 80%;
}
::v-deep div.el-table .el-table__body tr:hover td,
::v-deep div.el-table .el-table__body tr:hover span {
  font-weight: 400 !important;
  color: #fff !important;
}
::v-deep .el-radio__input.is-checked + .el-radio__label,
::v-deep .el-radio__label {
  color: #fff;
}
</style>
